<template>
  <div class="mobile-home">
    <div class="mobile-home-header">
      <img src="../../../assets/mobile_images/mo_case_bg.png" alt="" />
      <!-- <div class="mobile-header-btn">进一步了解</div> -->
    </div>
    <mobileTab :tabs="tabs" @tabChange="onTabChange" ></mobileTab>
    <div class="mobile-case-box">
      <div class="case-title">某大型上市药企—传世提供数字化及运营服务</div>
      <img class="w100f" :src="fbtImg1" alt="">
      <h2>案例目标</h2>
      <p>传世为客户提供一站式服务，帮助客户进行市场下沉、直达终端。</p>
      <h2>案例内容</h2>
      <p>传世以人网、仓网、车网的平台能力向客户提供了产品学术推广服务、终端数据采集服务、数据分析服务、供应链管理服务等一站式服务；</p>
      <p>通过实施下沉市场、贴近客户、精准拜访的策略帮助客户在半年间实现市场体量同比增长136%！FBT效果非常显著，双方顺势将合作扩大到了OTC、处方药、中药等领域！</p>
      <h2>解决方案</h2>
      <mobileImg :url="fbtImg2"  boxHeight="7.22rem" ></mobileImg>
      <h2>案例价值</h2>
      <p>数字供应链：保障了疫情期间的药品供应; 实现了冬储期备货前置; 前置仓的好处在于：缩短终端库存供给时间、降低退货数量、减少物流损耗等问题。</p>
      <p>数字营销：将人员管理成本，降低70%；市场推广成本，降低30%；货物破损成本，破损率降低78%；并同时将拜访有效率，提高至60%；采购意向转化率，提升至91.4%；物流时效，缩短了60%。</p>
      <h2>项目成果</h2>
      <img class="w92f mb_058" :src="fbtImg3" alt="">
      <img class="w100f" :src="fbtImg4" alt="">
    </div>

  </div>
</template>
<script>
import mobileTitle from '@/components/title/mobile_title.vue'; //标题组件
import mobileTab from '@/components/tab/mobile_tab.vue'; //选项卡组件
import mobileImg from '@/components/img/mobile_img.vue'; //图片组件
export default {
  components: {
    mobileTitle,
    mobileTab,
    mobileImg,
  },
  data() {
    return {
      fbtImg1:require('../../../assets/mobile_images/mo_case_fbt1.png'),
      fbtImg2:require('../../../assets/mobile_images/mo_case_fbt2.png'),
      fbtImg3:require('../../../assets/mobile_images/mo_case_fbt3.png'),
      fbtImg4:require('../../../assets/mobile_images/mo_case_fbt4.png'),
      currentIndex: 0,
      tabs: [
        { title: 'FBT案例', path: '/case/fbt' },
        { title: 'SPD案例', path: '/case/spd' },
        { title: '高值耗材柜案例', path: '/case/cabinet' },
        { title: '药链云案例', path: '/case/yly' },
      ],
    };
  },
  methods: {
    onTabChange(item, index) {
      this.currentIndex = index;
      // this.$refs.tabs.currentIndex = index
      console.log(index);
    },
  },
};
</script>
<style lang="less" scoped>
.mobile-home {
  margin: 0 auto;
  .mobile-home-header {
    position: relative;
    min-height: 2.6rem;
    img {
      width: 100%;
    }
    .mobile-header-btn {
      position: absolute;
      left: 50%;
      bottom: 1.8rem;
      transform: translateX(-50%);
      width: 2.4rem;
      height: 0.72rem;
      line-height: 0.72rem;
      font-size: 0.28rem;
      text-align: center;
      color: #fff;
      background: #4fa1a4;
      border-radius: 0.04rem;
    }
  }
  .mobile-case-box{
    color: #3D3D3D;
    padding: 0 .4rem;
    box-sizing: border-box;
    .case-title{
      font-size: .4rem;
      text-align: left;
      padding: .1rem 0 .28rem 0;
    }
    h2{
      font-size: .32rem;
      text-align: left;
      margin: .46rem 0 .42rem 0;
    }
    p{
      font-size: .28rem;
      line-height: .5rem;
      text-align: left;
      text-indent: 2em;
      margin-bottom: .2rem;
    }
    .mb_058{
      margin-bottom: .58rem;
    }
  }
}
</style>
