<template>
  <div class="home-block">
    <div class="home">
      <!-- 头部开始 -->
      <div class="home-header">
        <img class="w100f" src="../../assets/images/case_bg.png" alt="" />
        <div class="bg-text">
          <div class="bg-text-left">
            <p class="font-title">经典案例</p>
          </div>
        </div>
      </div>
      <!-- 头部结束 -->
      <!-- 中间内容开始 -->
      <div class="main-contain">
        <div class="h95"></div>
        <Tab :tabs="tabs" @tabChange="onTabChange" :currentIndex="0"> </Tab>
        <div class="font-title">某大型上市药企—传世提供数字化及运营服务</div>
        <img class="w100f" src="../../assets/images/case_fbt1.png" alt="" />
        <div class="font-title">案例目标</div>
        <p>传世为客户提供一站式服务，帮助客户进行市场下沉、直达终端。</p>
        <div class="font-title">案例内容</div>
        <p>
          传世以人网、仓网、车网的平台能力向客户提供了产品学术推广服务、终端数据采集服务、数据分析服务、供应链管理服务等一站式服务；
        </p>
        <p>
          通过实施下沉市场、贴近客户、精准拜访的策略帮助客户在半年间实现市场体量同比增长136%！FBT效果非常显著，双方顺势将合作扩大到了OTC、处方药、中药等领域！
        </p>
        <div class="font-title">解决方案</div>
        <img class="w100f" src="../../assets/images/medicine_case_block0.png" alt="" />
        <div class="font-title">案例价值</div>
        <p>
          数字供应链：保障了疫情期间的药品供应; 实现了冬储期备货前置;
          前置仓的好处在于：缩短终端库存供给时间、降低退货数量、减少物流损耗等问题。
        </p>
        <p>
          数字营销：将人员管理成本，降低70%；市场推广成本，降低30%；货物破损成本，破损率降低78%；并同时将拜访有效率，提高至60%；采购意向转化率，提升至91.4%；物流时效，缩短了60%。
        </p>
        <img class="w100f mb60 mt70" src="../../assets/images/case_fbt3.png" alt="" />
        <img class="w50f" src="../../assets/images/case_fbt4.png" alt="" />
      </div>
      <div class="h90"></div>
      <!-- <div class="case-grey-box">
      <div class="case-grey-content">
        <div class="font-title-two">项目成果</div>
        <img class="w100f mb60" src="../../assets/images/case_fbt3.png" alt="" />
        <img class="w50f" src="../../assets/images/case_fbt4.png" alt="" />
      </div>
    </div> -->
    </div>
    <mobilePage></mobilePage>
  </div>
</template>
<script>
import mobilePage from '@/views/case/mobile/case_fbt.vue'; //移动端页面
import Tab from '@/components/tab/tab.vue'; //选项卡组件
import Title from '@/components/title/title.vue'; //标题组件
export default {
  name: 'home',
  components: {
    Tab,
    Title,
    mobilePage
  },
  data() {
    return {
      tabs: [
        { title: 'FBT案例', path: '/case/fbt' },
        { title: 'SPD案例', path: '/case/spd' },
        { title: '高值耗材柜案例', path: '/case/cabinet' },
        { title: '药链云案例', path: '/case/yly' },
      ],
    };
  },
  methods: {
    onTabChange(item, index) {},
  },
};
</script>
<style lang="less" scoped>
.home {
  //头部
  .home-header {
    position: relative;
    width: 100%;
    .bg-text {
      width: 1200px;
      // height: 200px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      top: 42%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      .bg-text-left {
        text-align: left;
        .font-title {
          font-size: 40px;
          color: #3d3d3d;
        }
        .font-title-txt {
          font-size: 18px;
          font-weight: 400;
          line-height: 42px;
          color: #3d3d3d;
        }
        .next-btn {
          width: 140px;
          height: 40px;
          line-height: 40px;
          font-size: 18px;
          background: #4fa1a4;
          opacity: 1;
          border-radius: 24px;
          text-align: center;
          cursor: pointer;
        }
      }
      .bg-text-right {
        text-align: right;
        .font-one {
          font-size: 30px;
        }
        .font-two {
          font-size: 22px;
          line-height: 22px;
        }
      }
    }
  }
  //中间
  .main-contain {
    width: 1200px;
    .font-title {
      font-size: 32px;
      text-align: left;
      margin-top: 53px;
      margin-bottom: 40px;
    }
    p {
      font-size: 16px;
      text-align: left;
      text-indent: 2em;
      line-height: 30px;
      margin-bottom: 10px;
    }
    .font-title-one {
      font-size: 32px;
      text-align: center;
      margin: 60px 0 89px 0;
    }
    .case-img {
      width: 81%;
      margin-top: 60px;
      margin-bottom: 117px;
    }
  }
  //灰色区间
  .case-grey-box {
    width: 100%;
    // background: #fafafa;
    padding-top: 60px;
    padding-bottom: 102px;
    .case-grey-content {
      width: 1200px;
      margin: auto;
      .font-title-two {
        font-size: 32px;
        text-align: center;
        margin-bottom: 74px;
      }
    }
  }
}
</style>
